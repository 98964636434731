import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import face from './assets/circle-cropped.png'

const IntroText = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: palevioletred;
  float: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const CenterContainer = styled.section`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
`;

const InfoBox = styled.a`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  text-decoration: none;
  color: ${props => props.disabled ? "grey" : "palevioletred"};
  &:hover {
     color: ${props => props.disabled ? "grey" : "black"};
    }
`;

const Page = () => (
  <div style={{ display: "grid", marginTop: "10%", fontFamily: "Arial, Helvetica, sans-serif"}}>
    <CenterContainer>
      <IntroText>Hello, I'm Jaakko</IntroText>
      <img width="40px" alt="me" src={face} style={{marginLeft: "1em", float: "right"}}></img>
    </CenterContainer>
    
      <p style={{marginLeft: "auto", marginRight: "auto", color: "GrayText"}}><i>I may add something useful here later.</i></p>
    <CenterContainer>
      <InfoBox disabled>Blog</InfoBox>
      <InfoBox target="_blank" href="mailto:jaakko.lehtinen@live.fi">Email</InfoBox>
      <InfoBox target="_blank" href="https://github.com/j44kko">Github</InfoBox>
      <InfoBox target="_blank" href="https://fi.linkedin.com/in/jaakkol">LinkedIn</InfoBox>
    </CenterContainer>
  </div>
)

ReactDOM.render(<Page />, document.getElementById('root'))